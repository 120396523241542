import React from "react";
import ReactDOM from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { ChakraProvider, extendTheme } from "@chakra-ui/react";
import { colors } from "./colors.js";
import "./style.scss";
import ErrorPage from "./pages/errorPage.js";
import LoginPage from "./pages/loginPage.js";
import SignupPage from "./pages/signupPage.js";
import Root from "./components/Root.js";
import Profile from "./components/Profile.js";
import Index from "./components/Index.js";

const router = createBrowserRouter([
  {
    path: "/login",
    element: <LoginPage />,
  },
  {
    path: "/signup",
    element: <SignupPage />,
  },
  {
    path: "/",
    element: <Root />,
    errorElement: <ErrorPage />,
    children: [
      {
        errorElement: <ErrorPage />,
        children: [
          { index: true, element: <Index /> },
          {
            path: "profile/:userid",
            element: <Profile />,
          },
        ],
      },
    ],
  },
]);

const theme = extendTheme({
  colors: colors,
  fonts: {
    heading: `'brandon-grotesque', sans-serif`,
    body: `'Inter', sans-serif`,
  },
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <ChakraProvider theme={theme}>
      <RouterProvider router={router} />
    </ChakraProvider>
  </React.StrictMode>
);
