export const colors = {
  green: {
    50: "#F2F5EF",
    100: "#DBE3D4",
    200: "#C4D1B8",
    300: "#ADBF9C",
    400: "#96AD80",
    500: "#809B64",
    600: "#667C50",
    700: "#4D5D3C",
    800: "#333E28",
    900: "#1A1F14",
  },
  cyan: {
    50: "#EEF4F6",
    100: "#D0E1E6",
    200: "#B2CDD6",
    300: "#94BAC7",
    400: "#76A6B7",
    500: "#5893A7",
    600: "#467586",
    700: "#355864",
    800: "#233B43",
    900: "#121D21",
  },
  red: {
    50: "#FCE9E9",
    100: "#F7C0C0",
    200: "#F19898",
    300: "#EC6F6F",
    400: "#E64646",
    500: "#E11E1E",
    600: "#B41818",
    700: "#871212",
    800: "#5A0C0C",
    900: "#2D0606",
  },
  teal: {
    50: "#EEF7F5",
    100: "#CFE7E3",
    200: "#B0D8D1",
    300: "#92C9C0",
    400: "#73BAAE",
    500: "#54AB9C",
    600: "#43897D",
    700: "#32675E",
    800: "#22443E",
    900: "#11221F",
  },
  gray: {
    50: "#F5F1EF",
    100: "#E3D7D4",
    200: "#D1BDB8",
    300: "#BFA39C",
    400: "#AD8980",
    500: "#9B6F64",
    600: "#7C5950",
    700: "#5D433C",
    800: "#3E2C28",
    900: "#1F1614",
  },
  blue: {
    50: "#EEF4F6",
    100: "#D0E1E6",
    200: "#B2CDD6",
    300: "#94BAC7",
    400: "#76A6B7",
    500: "#5893A7",
    600: "#467586",
    700: "#355864",
    800: "#233B43",
    900: "#121D21",
  },
  orange: {
    50: "#FCF4E9",
    100: "#F7E0C0",
    200: "#F1CB98",
    300: "#ECB76F",
    400: "#E6A346",
    500: "#E18F1E",
    600: "#B47218",
    700: "#875612",
    800: "#5A390C",
    900: "#2D1D06",
  },
  yellow: {
    50: "#FCFCE9",
    100: "#F6F6C0",
    200: "#F1F198",
    300: "#EBEB70",
    400: "#E6E647",
    500: "#E0E01F",
    600: "#B3B319",
    700: "#868613",
    800: "#5A5A0C",
    900: "#2D2D06",
  },
  pink: {
    50: "#F8EAFB",
    100: "#EBC3F4",
    200: "#DF9CEC",
    300: "#D276E5",
    400: "#C54FDE",
    500: "#B929D6",
    600: "#9421AB",
    700: "#6F1881",
    800: "#4A1056",
    900: "#25082B",
  },
  purple: {
    50: "#F8EAFB",
    100: "#EBC3F4",
    200: "#DF9CEC",
    300: "#D276E5",
    400: "#C54FDE",
    500: "#B929D6",
    600: "#9421AB",
    700: "#6F1881",
    800: "#4A1056",
    900: "#25082B",
  },
};
