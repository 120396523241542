import { Form } from "react-router-dom";

export default function Profile() {
  const profile = {
    first: "Your",
    last: "Name",
    avatar: "https://placekitten.com/g/200/200",
    twitter: "your_handle",
    notes: "Some notes",
    favorite: true,
  };

  return (
    <div id="profile">
      <div>
        <img
          key={profile.avatar}
          src={profile.avatar || null}
        />
      </div>

      <div>
        <h1>
          {profile.first || profile.last ? (
            <>
              {profile.first} {profile.last}
            </>
          ) : (
            <i>No Name</i>
          )}{" "}
          <Favorite profile={profile} />
        </h1>

        {profile.twitter && (
          <p>
            <a
              target="_blank"
              href={`https://twitter.com/${profile.twitter}`}
            >
              {profile.twitter}
            </a>
          </p>
        )}

        {profile.notes && <p>{profile.notes}</p>}

        <div>
          <Form action="edit">
            <button type="submit">Edit</button>
          </Form>
          <Form
            method="post"
            action="destroy"
            onSubmit={(event) => {
              if (
                !window.confirm(
                  "Please confirm you want to delete this record."
                )
              ) {
                event.preventDefault();
              }
            }}
          >
            <button type="submit">Delete</button>
          </Form>
        </div>
      </div>
    </div>
  );
}

function Favorite({ profile }) {
  // yes, this is a `let` for later
  let favorite = profile.favorite;
  return (
    <Form method="post">
      <button
        name="favorite"
        value={favorite ? "false" : "true"}
        aria-label={
          favorite
            ? "Remove from favorites"
            : "Add to favorites"
        }
      >
        {favorite ? "★" : "☆"}
      </button>
    </Form>
  );
}
