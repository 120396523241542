import { Box, Flex, useColorMode } from "@chakra-ui/react";
import { Outlet } from "react-router-dom";
import Nav from "./Nav.js";
import Sidebar from "./Sidebar.js";

export default function Root() {
  const { colorMode, toggleColorMode } = useColorMode();
  return (
    <>
      <Nav />
      <Box>
        <Flex h={16} justifyContent={"space-between"}>
          <Sidebar />
          <Box w="100%">
            <Outlet />
          </Box>
        </Flex>
      </Box>
    </>
  );
}
